.rich-edit {
  background: transparent;
}

.RichTextEditor__root___2QXK- {
  background: transparent!important;
  border: none!important;
}

option {color: #454545!important;}
